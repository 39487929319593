import { useEffect } from 'react';
import initFullStory from './initFullStory';

const useFullStory = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      initFullStory();
    }
  }, []);
};

export default useFullStory;
