import React from 'react';
import { Helmet } from 'react-helmet';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import useFullStory from '../../../hooks/useFullStory';
import useSiteMetadata from '../../../hooks/useSiteMetadata';

type SEOLayoutProps = {
  pageTitle?: string | undefined | null;
  [otherProps: string]: any;
};

const SEOLayout = (props: SEOLayoutProps) => {
  const { children, pageTitle } = props;

  const { title, description } = useSiteMetadata();

  useFullStory();

  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>{ pageTitle || title }</title>
        <meta name="description" content={description} />
        <script
          src="https://leadbooster-chat.pipedrive.com/assets/loader.js"
          async
        />
      </Helmet>
        {children}
    </>
  );
};

SEOLayout.defaultProps = {
  pageTitle: null
}

export default SEOLayout;
